import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/lib/locale/ru';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru',
    },
    theme: {
        themes: {
          light: {
            dividerColor: '#d2dae3',
            dividerContrastColor: '#d2dae3',
            primary: '#1867c0', // #E53935 colors.indigo.base
            secondary: '#FFCDD2', // #FFCDD2
            accent: '#FFCDD2', // #3F51B5
            error: '#FF5252',
            info: '#2196F3',
            success: '#4caf50',
            warning: '#FFC107',
            stud: '#243a51',
            studSite: '#243a51',
            neon: '#262335',
            orange: '#ff9800',
            green: '#4caf50',
            studGreen: '#3d963d',
            indigo: '#3f51b5',
            pink: '#e91e63',
            teal: '#009688',
            purple: '#9c27b0',
            dstu: '#243a51',
          },
          dark: {
            dividerColor: '#394358',
            dividerContrastColor: '#d2dae3',
            background: '#303030',
            primary: '#2196f3', // #E53935
            secondary: '#FFCDD2', // #FFCDD2
            accent: '#3F51B5', // #3F51B5
            error: '#FF5252',
            info: '#2196F3',
            success: '#4caf50',
            warning: '#FFC107',
            stud: '#2f73a7', // 243a51  eac923  0b80dc 0a71c1 3d5f79 5b8fb7
            studSite: '#2f73a7', // 243a51  eac923  0b80dc 0a71c1 3d5f79 5b8fb7
            neon: '#262335',
            orange: '#ff9800',
            green: '#4caf50',
            studGreen: '#3d963d',
            indigo: '#3f51b5',
            pink: '#e91e63',
            teal: '#009688',
            purple: '#9c27b0',
            dstu: '#303030',
            navi: '#eae42f'
          }
        },
        options: {
          customProperties: true,
        },
      }
    // theme: {
    //     themes: {
    //         light: {
    //             primary: '#243a51'
    //         }
    //     }
    // }
});
