import axios from 'axios'
import Vue from 'vue'
import CookieService from '@/helpers/cookie'
export default (withCredentials = false) => {
  // let cookieToken = CookieService.getCookie('authToken')
  // console.log(cookieToken)
  // if(cookieToken === undefined){
  //   if(localStorage.authToken != undefined){
  //     CookieService.setCookie = localStorage.authToken
  //   }
  // }
  let token = CookieService.getCookie('authToken')
  //console.log(Vuex.Store.getters)
  let axi = axios.create({
    baseURL: Vue.prototype.$api.address,
    withCredentials: withCredentials,
    'headers': {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'Authorization': 'Bearer ' + token,
      'fp': sessionStorage.getItem('fp'),
      'Client-Version': localStorage.getItem('version'),
      'Current-Path': document.location.href
    }
  })
  axi.interceptors.response.use(null, async (err) => {
    if (err.response !== undefined && err.response.request !== undefined && err.response.request.responseURL.toLocaleLowerCase().includes('signalr/api')) {
      return
    }
    if (err.response !== undefined && err.response.status !== undefined) {
      let isStructureError = err.response.data.msg.includes('Недопустимое имя столбца') || err.response.data.msg.includes('Недопустимое имя объекта')
      let isFieldError = err.response.data.msg.includes('Символьные или двоичные данные могут быть усечены')
      console.log(err.response.data.msg)
      let text = 'Ошибка запроса к API'
      let subText = ''
      if (isStructureError) {
        text = 'Ошибка структуры БД'
      }
      if (isFieldError) {
        text = 'Ошибка размерности поля в БД'
      }
      Vue.notify({
        group: 'error',
        title: subText === '' ? 'Ошибка!' : 'Ошибка запроса к API',
        text: text,
        type: 'error'
      })
      // alert('Ошибка')
    }
    if (err.response === undefined) {
      if (!err.config.url.toLowerCase().includes('api/development/errorslogclient')) {
        // if (location.href.includes('stud.sssu.ru') || location.href.includes('edu.donstu.ru') || location.href.includes('lk.donstu.ru')) {
        //   Vue.prototype.$api.post('/api/development/errorslogclient', {
        //     userID: store.getters.userID,
        //     clientRoute: location.href,
        //     serverRoute: err.config.url,
        //     method: err.config.method,
        //     message: err.message
        //   })
        // }
        Vue.notify({
          group: 'error',
          title: 'Ошибка запроса к API',
          text: 'Сервер недоступен',
          type: 'error'
        })
      }
    }
    if (err.response !== undefined && err.response.status !== undefined && err.response.status === 401) {
      // CookieService.setCookie('authToken', '', { path: '/' })
      // window.location = ''
    }
    return Promise.reject(err)
  })
  return axi
}
