<template>
    <v-app id="login" class="appLoginLight"
         :style="showDGTU ? 'background-image: linear-gradient(269.63deg, rgba(90, 176, 255, 0.5) -44.48%, rgba(0, 44, 108, 0.5) 87.2%), url(/img/webapp/login1.jpg);' : ''"
         v-resize="onResize">
    <v-main style="z-index:2">
      <v-btn v-if="false" icon color="red" fixed right top>
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-container style="height: 90% !important;" fill-height :class="!isMobile && showDGTU ? 'container--fluid pr100': ''">
        <v-layout align-center v-if="true" :class="isMobile || !showDGTU ? 'justify-center': 'justify-end pr100'">
          <v-flex xs12 sm8 md6 lg4 style="max-width: 400px;">
            <v-fade-transition>
              <v-card v-if="showExternal && clientApp && $route.name === 'authorize'" class="mb-10 pa-3">
                <img v-if="clientApp.iconUrl" style="height: 80px;" :src="clientApp.iconUrl"><br
                v-if="clientApp.iconUrl">
                <span class="font-weight-bold">{{ clientApp.name }}</span> запрашивает доступ к вашему профилю
              </v-card>
            </v-fade-transition>
            <v-fade-transition>
              <v-card v-if="message" class="mb-10 pa-3">
                {{message}}
              </v-card>
            </v-fade-transition>
            <v-slide-y-transition mode="out-in">
              <v-card class="elevation-1 pa-3 text-center"
                      :class="$vuetify.theme.dark ? 'FormLoginDark': 'FormLoginLight'"
                      v-if="showForm && mode === 'login' ">
                <v-card color="stud lighten-2" class="pa-4 white--text text-center"
                        style="top: -30px;background: linear-gradient(269.63deg, rgba(90, 176, 255, 0.5) -44.48%, rgba(0, 44, 108, 0.5) 87.2%);justify-content: center;display: flex;align-items: center;">
                  <v-icon v-if="!showNameUniversity" class="mr-3" color="white" left>mdi-account</v-icon>
                  <v-img
                    v-else
                    class="mr-3"
                    :src="'/img/logo/logo.png' "
                    max-height="180"
                    :max-width="isTablet ? 40 : 40"
                    contain
                  ></v-img>
                  <span class="display-0 font-weight-bold" :style="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '':'font-size: 18pt;'"> {{ !forParentMode ? (showNameUniversity ? initInfo.shortName : 'Личный кабинет') : 'Вход для родителей' }}
                  </span>
                </v-card>
                <v-card-text class="pb-0">
                  <div>
                    <v-form ref="formLogin" lazy-validation>
                      <v-text-field
                        ref="loginInputFormLogin"
                        color="stud"
                        append-icon="mdi-account"
                        name="login"
                        label="Логин"
                        placeholder="Введите логин"
                        type="text"
                        v-model.trim="model.userName"
                        :rules="[() => !!model.userName || '']"
                        @input="model.userName ? model.userName = model.userName.replaceAll(' ', ''): null"
                        @keydown="getSubmitEnter($event)"
                      />
                      <v-text-field
                        ref="passwordInputFormLogin"
                        color="stud"
                        :append-icon="!showPass ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                        name="password"
                        label="Пароль"
                        id="password"
                        placeholder="Введите пароль"
                        :type="showPass ?'text':'password'"
                        @click:append="showPass = !showPass"
                        v-model="model.password"
                        :rules="[() => !!model.password || '']"
                        @input="model.password ? model.password = model.password.replaceAll(' ', ''): null"
                        @keydown="getSubmitEnter($event)"
                      />
                    </v-form>
                  </div>
                  <span class="error-msg" v-if="error">{{ error }}</span>
                  <span class="error-msg"
                        v-if="this.$store.state.thisErrorToken != null">{{ this.$store.state.thisErrorToken.msg }}</span>
                </v-card-text>
                <v-layout class="pa-2" v-if="allowParentLogin">
                  <v-chip @click="forParentMode = !forParentMode" dark color="stud">{{ forParentMode ? 'Для обучающихся' : 'Для родителей' }}</v-chip>
                </v-layout>
                <v-layout row wrap class="pa-3 no-flex-baby"
                          v-if="url && (url.indexOf('nir.mmis.ru') > -1 || url.indexOf('test.mmis.ru') > -1)">
                  <v-flex pa-1 v-for="user in customUsers" :key="user.userID">
                    <v-chip dark color="stud" @click="loginAs(user.login)">{{ user.name }}</v-chip>
                  </v-flex>
                </v-layout>
                <v-subheader v-if="url && (demoStudent) && lstDemoKabs.length > 0" style="height: 28px;">Демо кабинет:</v-subheader>
                <v-layout row wrap class="pa-2 ml-0 no-flex-baby" v-if="url && (demoStudent) && lstDemoKabs.length > 0">
                  <v-flex pa-1 v-for="user in lstDemoKabs" :key="user.userID">
                    <v-chip dark color="stud" @click="loginAs(user.login)">{{ user.name }}</v-chip>
                  </v-flex>
                </v-layout>
                <!--                <v-layout class="mx-2" v-if="isBeta">-->
                <!--                  <v-spacer></v-spacer>-->
                <!--                  <v-chip  to="/Rasp/List" small color="stud" class="white&#45;&#45;text">Войти как гость</v-chip>-->
                <!--                </v-layout>-->
                <v-layout class="px-3" v-if="confirmLoginHTML">
                  <v-checkbox v-model="confirmLoginValue"></v-checkbox>
                  <div style="text-align: left" class="my-auto" v-html="confirmLoginHTML"></div>
                </v-layout>
                <v-card-actions>
                  <v-btn
                    style="margin: 0 auto;"
                    block
                    color="stud"
                    class="white--text"
                    type="submit"
                    @click="login"
                    :loading="loading && this.$store.state.thisErrorToken == null"
                    :disabled="logged || !allowLogin"
                  >Войти
                  </v-btn>
                </v-card-actions>
                <v-expand-transition>
                  <v-card-actions v-if="vkClientID && !forParentMode">
                    <v-btn
                      style="margin: 0 auto;"
                      block
                      color="#4a76a8"
                      type="submit"
                      class="white--text"
                      :disabled="(loading && this.$store.state.thisErrorToken == null) || !allowLogin"
                      @click="loginExternal('vk')"
                    > Войти через
                      <v-icon class="mb-1" right>mdi-vk</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-expand-transition>
                <v-expand-transition>
                  <v-card-actions v-if="moodleClientId && !forParentMode && (moodleIsActive === '1') && (moodleIsButtonActive === '1')">
                    <v-btn
                      style="margin: 0 auto;"
                      block
                      color="#4a76a8"
                      type="submit"
                      class="white--text"
                      @click="loginExternal('moodle')"
                    >
                      <span v-if="moodleButtonSignName.length > 0">{{moodleButtonSignName}}</span>
                      <span v-else>Войти через moodle</span>
                    </v-btn>
                  </v-card-actions>
                </v-expand-transition>
                <v-expand-transition v-for="form in activeClients" :key="form.id">
                  <v-card-actions v-if="!forParentMode">
                    <v-btn :color="form.buttonColor"
                           :elevated="form.buttonStyle === 'elevated'"
                           :fab="form.buttonStyle === 'fab'"
                           :rounded="form.buttonStyle === 'rounded'"
                           :outlined="form.buttonStyle === 'outlined'"
                           :text="form.buttonStyle === 'text'"
                           :plain="form.buttonStyle === 'plain'"
                           style="margin: 0 auto;"
                           block
                           @click="loginExternal('universal', form)"
                    >
                      <span v-if="form.nameButtonSign.length > 0">{{form.nameButtonSign}}</span>
                      <span v-else>{{form.name}}</span>
                      <img v-if="form.linkIconButtonSign.length > 0"
                           :src="form.linkIconButtonSign"
                           width="16"
                           height="16"
                           class="ml-1"
                           alt="icon sign"/>
                    </v-btn>
                  </v-card-actions>
                </v-expand-transition>
                <v-expand-transition>
                  <v-card-actions v-if="yandexClientID && !forParentMode">
                    <v-btn
                      style="margin: 0 auto;"
                      block
                      color="white"
                      type="submit"
                      :disabled="(loading && this.$store.state.thisErrorToken == null) || !allowLogin"
                      @click="loginExternal('yandex')"
                    > Войти через
                      <v-icon color="red" class="mb-1" right>fab fa-yandex</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-expand-transition>
                <v-expand-transition>
                  <v-card-actions v-if="googleClientID && !forParentMode && (!googleRedirectUri.includes('localhost') || isDebug)">
                    <v-btn
                      style="margin: 0 auto;"
                      block
                      type="submit"
                      :disabled="(loading && this.$store.state.thisErrorToken == null) || !allowLogin"
                      @click="loginExternal('google')"

                    > Войти через
                      <v-icon class="mb-1" right>mdi-google</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-expand-transition>
                <v-expand-transition>
                  <v-card-title v-if="url.indexOf('nir.mmis.ru') === -1 && !forParentMode && showRegistration && hideTextForStudent !== true">
                    Для обучающихся:
                  </v-card-title>
                  <div v-else-if="hideTextForStudent === true" style="height: 8px;"></div>
                </v-expand-transition>
                <v-layout v-if="url.indexOf('nir.mmis.ru') === -1 && showRegistration" row :class="'mx-2'" :style="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? 'display: grid;':''">
                  <v-btn v-if="forParentMode || usePersonalKabRegistration" :rounded="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" small color="stud" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' :'mr-2'" class="white--text mb-2" @click="changeMode()">
                    Регистрация
                  </v-btn>
                  <v-btn v-else small color="stud" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' :'mr-2'" :rounded="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" class="white--text mb-2"
                         href="/Account/Register.aspx">Регистрация
                  </v-btn>
                  <v-btn v-if="forParentMode || usePersonalKabRegistration" :rounded="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" small color="stud" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' :'mr-2'" class="white--text mb-2"
                         @click="fPass = true, changeMode()">Восстановление пароля
                  </v-btn>
                  <v-btn v-else small color="stud" :rounded="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '' :'mr-2'" class="white--text mb-2"
                         href="/Account/Register.aspx?fPass=1">Восстановление пароля
                  </v-btn>
                </v-layout>
              </v-card>
              <v-card class="elevation-1 pa-3" :class="$vuetify.theme.dark ? 'FormLoginDark': 'FormLoginLight'"
                      v-else-if="mode === 'registration'">
                <registration-form @changeMode="changeMode" :forParentMode="forParentMode" :extendedRegistration="extendedRegistration" :fPass="fPass" />
              </v-card>
              <div v-else></div>

            </v-slide-y-transition>
            <!--            <v-layout row class="mx-2 mt-3" >-->
            <!--              <v-btn v-if="true" small color="white" rounded class="mr-2 mb-2" href="stud.sssu.ru">ИСОиП</v-btn>-->
            <!--            </v-layout>-->
            <v-slide-y-transition mode="out-in">
              <v-card class="pa-2" style="margin-top: 12px;" v-show="showHint && showForm && showAdditional && mode === 'login'">
                <p style="font-size: 17px; font-weight: 500; margin-bottom: 1px; text-align: start;">Подсказка:</p>
                <p style="margin-bottom: 1px; text-align: start;" v-html="hint"></p>
              </v-card>
            </v-slide-y-transition>
            <v-slide-y-transition mode="out-in">
              <v-layout v-show="showForm && showAdditional && mode === 'login'" style="margin-top: 12px;">
                <v-btn :rounded="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)" block :color="!$vuetify.theme.dark ? 'white': 'stud'" href="/"><v-icon left>mdi-home</v-icon> На главную</v-btn>
              </v-layout>
            </v-slide-y-transition>
          </v-flex>

        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import RegistrationForm from "@/components/RegistrationForm";
import login from "@/helpers/api/login";
import CookieService from "@/helpers/cookie";
import fingerprint from '@/helpers/fingerprint'
const minNumbersChars = 5
export default {
  name: 'login',
  components: { RegistrationForm },
  data: () => ({
    mode: 'login',
    allowParentLogin: false,
    forParentMode: false,
    fPass: false,
    rules: {
      required: value => !!value || '',
      minNumbersChars: (value) => minNumbersChars > 0 ? ((!!value.trim() && value.trim().replaceAll(' ', '').length >= minNumbersChars) || '') : true,
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || ''
      }
    },
    snowConf: {
      windPower: 3,
      speed: 2,
      count: 5,
      size: 20,
      opacity: 0.2,
      color: '#ffffff',
      images: ['https://raw.githubusercontent.com/bob-chen/let_it_snow/master/demo/snow.png']
    },
    snowConf2: {
      windPower: 4,
      speed: 2,
      count: 30,
      size: 3,
      opacity: 1,
      color: '#ffffff',
      images: []
    },
    showHint: false,
    showForm: false,
    showAdditional: false,
    showExternal: false,
    showPass: false,
    checkAuth: false,
    loader: null,
    loading: false,
    error: null,
    hint: '',
    showRegistration: true,
    confirmLoginValue: false,
    model: {
      userName: '',
      password: ''
    },
    customUsers: [
      { id: 1, name: 'Студент', login: 'student' },
      { id: 2, name: 'Преподаватель', login: 'author' },
      { id: 3, name: 'Экспорт', login: 'export' },
      { id: 4, name: 'Эксперт (заключения)', login: 'expert1' },
      { id: 5, name: 'Эксперт (акты)', login: 'expert2' },
      { id: 6, name: 'НТБ', login: 'bibl' }
    ],
    yandexClientID: '',
    yandexRedirectUri: '',
    vkClientID: '',
    vkRedirectUri: '',
    googleClientID: '',
    googleRedirectUri: '',
    moodleButtonSignName: '',
    moodleClientId: '',
    moodleIsActive: '0',
    moodleIsButtonActive: '0',
    moodleServerUrl: '',
    activeClients: [],
    clientApp: null,
    isMobile: false,
    extendedRegistration: false,
    message: null,
    confirmLoginHTML: '',
    showNameUniversity: null,
    demoStudent: false,
    hideTextForStudent: true,
    usePersonalKabRegistration: false,
    lstDemoKabs: []
  }),
  computed: {
    allowLogin () {
      return this.confirmLoginHTML === '' || this.confirmLoginHTML === null || this.confirmLoginValue === true
    },
    cuserID () {
      return this.$store.getters.currentID
    },
    initInfo () {
      return this.$store.state.initInfo
    },
    redirectURL () {
      return this.$route.name !== 'Login' ? document.URL.substr(document.URL.indexOf('#') + 1) : ''
    },
    isDebug () {
      return process.env.NODE_ENV === 'development'
    },
    showDGTU () {
      return document.location.href.includes('edu.donstu.ru') || document.location.href.includes('lk.donstu.ru')
    },
    isBeta () {
      return this.$store.getters.betaMode
    },
    logged () {
      return false
    },
    url () {
      return document.URL
    }
  },
  mounted () {
    if (this.cuserID) {
      this.$router.push('/')
    }
    if (this.$route.name === 'authorize' || document.URL.toLowerCase().includes('/#/authorize')) {
      let url = new URL(document.URL.replace('#/', ''))
      let clientID = url.searchParams.get('client_id')
      if (clientID) {
        this.loadClientInfo(clientID)
      }
    }
    setTimeout(() => {
      this.showForm = true
    }, 500)
    setTimeout(() => {
      this.showAdditional = true
    }, 800)


    // this.show = (localStorage.getItem('disableSnow') == undefined) && this.$cParams.allowSnowMode
    this.init()
    if (this.initInfo && this.initInfo.useCaptcha) {
      if (this.$recaptchaInstance !== undefined){
        this.$recaptchaInstance.showBadge()
      }
    }
    // this.getHint()
    // Штука для исправления бага с автозаполнением хрома
    setTimeout(() => {
      const els = document.querySelectorAll("input:-webkit-autofill")
      els.forEach((el) => {
        const label = el.parentElement.querySelector("label")
        label.classList.add("v-label--active")
      })
    }, 1000)
  },
  destroyed () {
    if (this.$recaptchaInstance !== undefined) {
      this.$recaptchaInstance.hideBadge()
    }
  },
  watch: {
    loader () {
      const l = this.loader
      this[l] = !this[l]
      setTimeout(() => (this[l] = false), 3000)
      this.loader = null
    }
  },
  methods: {
    onResize () {
      if (window.innerWidth < 1300) {
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    },
    loginExternal (type, form) {
      let nonce = this.randomString(10)
      CookieService.setCookie('OAuthNonceKey', nonce, { path: '/' })
      if (this.redirectURL) {
        CookieService.setCookie('OAuthRedirect', this.redirectURL, { path: '/' })
      } else {
        CookieService.setCookie('OAuthRedirect', '/', { path: '/' })
      }
      let url = ''
      if (type === 'vk') {
        url = `https://oauth.vk.com/authorize?client_id=${this.vkClientID}&redirect_uri=${this.vkRedirectUri}&display=page&state=` + nonce
      }
      if (type === 'yandex') {
        url = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${this.yandexClientID}&redirect_uri=${this.yandexRedirectUri}&state=` + nonce
      }
      if (type === 'google') {
        url = `https://accounts.google.com/o/oauth2/auth?redirect_uri=${this.googleRedirectUri}&response_type=code&client_id=${this.googleClientID}&scope=https://www.googleapis.com/auth/userinfo.email&state=${nonce}`
      }
      if (type === 'moodle') {
        url = `${this.moodleServerUrl}/local/oauth/login.php?client_id=${this.moodleClientId}&response_type=code`
      }
      if (type === 'universal'){
        //console.log("form", form)
        let client = form
        let redirectURLLocal = ""
        if (client.basicDomainSite.slice(-1) === "/")
          redirectURLLocal = encodeURIComponent(client.basicDomainSite + "api/auth/baseoauth?id=" + form.id)
        else
          redirectURLLocal = encodeURIComponent(client.basicDomainSite + "/api/auth/baseoauth?id=" + form.id)

        url = `${client.authorization_endpoint}?client_id=${client.clientId}&redirect_uri=${redirectURLLocal}&state=randomrandom&response_type=code&scope=openid email`
      }
      if (url) {
        console.log("url", url)
        document.location.href = url
      }
    },
    randomString (length) {
      var charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz'
      let result = ''
      while (length > 0) {
        var bytes = new Uint8Array(16)
        var random = window.crypto.getRandomValues(bytes)

        random.forEach(function (c) {
          if (length == 0) {
            return
          }
          if (c < charset.length) {
            result += charset[c]
            length--
          }
        })
      }
      return result
    },
    async loadClientInfo (clientID) {
      let response = await this.$api.get('/api/auth/oauth/app?appID=' + clientID)
      this.clientApp = response.data.app
      if (!this.clientApp) {
        window.location.href = '/WebApp/#/Login'
      }
      setTimeout(() => {
        this.showExternal = true
      }, 1000)
    },
    async init () {
      let response = await this.$api.get('/api/auth/settings')
      console.log("response", response)
      this.hint = response.data.hint
      this.showNameUniversity = response.data.showNameUniversity
      this.showHint = this.hint !== null && this.hint !== ''
      this.showRegistration = !response.data.domainAuthStudent && response.data.allowRegisterStudents
      this.demoStudent = response.data.demoStudent
      this.lstDemoKabs = response.data.lstDemoKabs
      this.vkClientID = response.data.vkClientID
      this.vkRedirectUri = response.data.vkRedirectUri
      this.moodleButtonSignName = response.data.moodleButtonSignName
      this.moodleClientId = response.data.moodleClientId
      this.moodleIsActive = response.data.moodleIsActive
      this.moodleIsButtonActive = response.data.moodleIsButtonActive
      this.moodleServerUrl = response.data.moodleServerUrl
      this.yandexClientID = response.data.yandexClientID
      this.yandexRedirectUri = response.data.yandexRedirectUri
      this.googleClientID = response.data.googleClientID
      this.googleRedirectUri = response.data.googleRedirectUri
      this.activeClients = response.data.activeClients
      this.allowParentLogin = response.data.allowParentLogin
      this.confirmLoginHTML = response.data.confirmLoginHTML
      this.extendedRegistration = response.data.extendedRegistration
      this.hideTextForStudent = response.data.hideTextForStudent
      this.usePersonalKabRegistration = response.data.usePersonalKabRegistration
    },
    changeMode (message) {
      if (!message) {
        this.message = ''
      }
      let mode = JSON.parse(JSON.stringify(this.mode))
      setTimeout(() => {
        this.message = message
        this.mode = (mode === 'login' ? 'registration' : 'login')
        if (this.mode === 'login') {
          this.fPass = false
        }
      }, 300)
      this.mode = ''
    },
    getSubmitEnter (value) {
      if ((value.keyCode === 32)) {
        return value.preventDefault()
      }
      if (value.keyCode === 13 && this.allowLogin === true) {
        this.login()
      }
    },
    async getHint () {
      let response = await this.$api.get(
        '/api/registrHint')
      this.hint = response.data
      this.showHint = this.hint !== null && this.hint !== ''
    },
    loginAs (login) {
      this.model.userName = login
      this.model.password = 'testMe'
      this.login()
    },
    async login () {
      sessionStorage.setItem('loginAsInfo', '')
      this.message = ''
      if (this.$refs.formLogin.validate()) {
        this.loading = true
        this.$store.state.thisErrorToken = null
        let fp = await fingerprint.getFingerprint()
        let recaptchaToken = null
        if (this.$recaptchaLoaded !== undefined && this.$store.state.initInfo && this.$store.state.initInfo.useCaptcha === true) {
          await this.$recaptchaLoaded()
          recaptchaToken = await this.$recaptcha('login')
        }
        this.$store.dispatch('retrieveToken', {
          userName: this.model.userName,
          password: this.model.password,
          isParent: this.forParentMode,
          fingerprint: fp,
          recaptchaToken: recaptchaToken
        })
          .then(data => {
            this.showForm = false
            this.showAdditional = false
            setTimeout(() => {
              this.loading = false
            }, 1000)
            let search = window.location.href.substring(window.location.href.lastIndexOf('?'))
            let redirectUrlParams = search.match(new RegExp('[\?\&]' + 'ReturnUrl' + '=([^\&]*)(\&?)', 'i'))
            let redirectUrl = redirectUrlParams ? redirectUrlParams[1] : redirectUrlParams
            if (redirectUrl) {
              redirectUrl = redirectUrl.replaceAll('%2F', '/')
              window.location.href = redirectUrl
            }

            this.error = null
            this.$store.state.thisErrorToken = null
          })
          .catch(error => {
            console.log(error)
            let msg = 'Сервер не отвечает'
            if (error.state === -1 && error.msg) {
              msg = error.msg
            }

            this.$store.state.thisErrorToken = null
            this.error = msg
            this.loading = false
            return this.error
          })
        this.checkAuth = true
      }
    },
    LogOut () {
      login.logOut()
      this.$store.dispatch('destroyToken')
    }
  }
}
</script>
<style>
.pr100 {
  padding-right: 100px !important;
}

.no-flex-baby div.flex {
  flex: none !important;
}

.FormLoginDark input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
  color: white !important;
  -webkit-text-fill-color: white;
}

.FormLoginLight input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

input:not(:-webkit-autofill) {
  animation-name: onAutoFillCancel;
}
</style>
<style>
/* .theme--dark.v-input:not(.v-input--is-disabled) input{
  color: #fff !important;
} */
</style>
<style scoped lang="css">
.theme--dark.v-input:not(.v-input--is-disabled) input {
  color: #fff !important;
}

#login.appLoginDark {
  /*
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  */
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/assets/dstu.jpg); */
  background-color: #3d4ca7;
  background-position: top center;
}

#login.appLoginLight {
  /*
  background-size: cover;
  min-height: 100vh;
  background-repeat: no-repeat;
  */
  /*background-image: linear-gradient(to top, rgb(36, 58, 81), rgba(0, 0, 0, 0.5)), url(https://demos.creative-tim.com/vuetify-material-dashboard-pro/img/login.d6d3bb09.jpg);*/
  background-image: linear-gradient(to top, rgba(36, 58, 81, 0.5), rgba(0, 0, 0, 0.5)), url(/img/webapp/login.jpg);
  background-size: cover;
  /* height: 100%; */
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
  /* background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(/assets/dstu.jpg); */
  background-color: rgb(36, 58, 81);
  background-position: center top;
}

.error-msg {
  color: red;
}

.fade-enter-active, .fade-leave-active {
  transition: all .7s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

</style>
