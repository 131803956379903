/*
    Реализация основных HTTP методов
*/
import Api from '@/helpers/api'
import Vue from 'vue'
export default {
  maxErrors: 1,
  address: '',
  offConsole: true,
  async get (link, params = null, countErrors = 0) {
    let dateStart = new Date()
    let query = '?'
    for (let key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        query += key + '=' + params[key] + '&'
      }
    }
    if (query !== '?') {
      query = query.substr(0, query.length - 1)
      link = link + query
    }

    return Api().get(link)
      .then(response => {
        if (response) {
          this.addToHistory('GET', link, dateStart, new Date().getTime() - dateStart.getTime(), response.benchmark ? response.benchmark : null)
          if (!link.includes('CheckMail') && !this.offConsole) {
            console.log('GET ' + link, response.data)
          }
          return response.data
        } else {
          return null
        }
      }).catch((err) => {
        // if (err.message === 'Network Error') {
        //   if(countErrors <= this.maxErrors) {
        //     return await this.get(link, params, countErrors += 1)
        //   }
        // } else {
        //   return { data: null, state: -3, msg: 'Ошибка запроса к API' }
        // }
        return { data: null, state: -3, msg: 'Ошибка запроса к API' }
      })
  },
  async post (link, body, params = null, withCredentials = false) {
    let dateStart = new Date()
    let isFromData = body instanceof FormData
    if (isFromData) {
      params = { headers: { 'Content-Type': 'multipart/form-data' } }
    }
    return Api(withCredentials).post(link, body, params)
      .then(response => {
        if (response) {
          this.addToHistory('POST', link, dateStart, new Date().getTime() - dateStart.getTime(), response.benchmark ? response.benchmark : null)
          if (!this.offConsole) {
            console.log('POST ' + link, response.data)
          }
          return response.data
        } else {
          return null
        }
      }).catch(() => {
        return { data: null, state: -3, msg: 'Ошибка запроса к API' }
      })
  },
  async put (link, body) {
    let dateStart = new Date()
    return Api().put(link, body)
      .then(response => {
        if (response) {
          this.addToHistory('PUT', link, dateStart, new Date().getTime() - dateStart.getTime(), response.benchmark ? response.benchmark : null)
          if (!this.offConsole) {
            console.log('PUT ' + link, response.data)
          }
          return response.data
        } else {
          return null
        }
      }).catch(() => {
        return { data: null, state: -3, msg: 'Ошибка запроса к API' }
      })
  },
  // Api().put('/api/Publication/Admin/balls/' + id, ball)
  async delete (link, params) {
    let dateStart = new Date()
    let query = '?'
    for (let key in params) {
      query += key + '=' + params[key] + '&'
    }
    if (query !== '?') {
      query = query.substr(0, query.length - 1)
      link = link + query
    }
    return Api().delete(link)
      .then(response => {
        if (response) {
          this.addToHistory('DELETE', link, dateStart, new Date().getTime() - dateStart.getTime(), response.benchmark ? response.benchmark : null)
          if (!this.offConsole) {
            console.log('DELETE ' + link, response.data)
          }
          return response.data
        } else {
          return null
        }
      }).catch(() => {
        return { data: null, state: -3, msg: 'Ошибка запроса к API' }
      })
  },
  addToHistory (method, link, date, duration, benchmark) {
    // Vue.prototype.$requestsHistory.push({ method: method, link: link, date: date, duration: duration, benchmark: benchmark })
  }
}
