export default {
    getCurrentYear (month = 7) {
        if (new Date().getMonth() < month) {
            return (new Date().getFullYear() - 1) + '-' + (new Date().getFullYear())
        } else {
            return (new Date().getFullYear()) + '-' + (new Date().getFullYear() + 1)
        }
    },

    getYearsList (startYear, includeAll = false) {
        if (startYear == null) {
            startYear = 2010
        }
        let years = []
        if (includeAll === true) {
            years.push('Все')
        }
        for (let i = startYear; i <= new Date().getFullYear(); i++) {
            years.push(i + '-' + (i + 1))
        }
        return years
    },
    getCurrentSem (month = 7) {
        let now = new Date()
        if (now.getMonth() < month && now.getMonth() > 0) {
            return 0
        } else {
            return 1
        }
    }
}