<template>
  <v-dialog persistent v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="text-left black--text">{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Ок</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: 'Alert',
  data () {
    return {
      showDialog: false,
      text: 'Тест',
      title: 'Сообщение',
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  methods: {
    show (opts = {}) {
      console.log(opts)
      if (typeof opts === 'string' || opts instanceof String) {
        this.text = opts
      } else {
        this.text = opts.text
        this.title = opts.title
      }

      this.showDialog = true

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    cancel () {
      this.showDialog = false
      this.resolvePromise(false)
    },
    confirm () {
      this.showDialog = false
      this.resolvePromise(true)
    }
  }
}
</script>

<style scoped>

</style>
