<template>
  <div>
    <!-- <div v-if="this.$route.meta && this.$route.meta.layout === 'empty'">
      <router-view></router-view>
    </div>
    {{$route.meta.requiresAuth}} -->
    <confirm ref="confirm"></confirm>
    <alert ref="alert"></alert>
    <template v-if="!connected">
      <v-app id="inspire">
        <transition>
          <keep-alive>
            <loading-dialog :newLoaderPage="true" :dialog="!connected"></loading-dialog>
          </keep-alive>
        </transition>
      </v-app>
    </template>
    <template v-else-if="!cuserID && $route.meta.requiresAuth !== false && $route.meta.layout === 'empty'">
      <transition>
        <keep-alive>
          <login></login>
        </keep-alive>
      </transition>
    </template>
    <template v-else-if="($route.meta.requiresAuth === false || cuserID) && ($route.meta.layout === 'empty')">
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <v-app v-else>
      <main-layout>
        <!-- style="padding-bottom: 16px;" -->
        <v-main >
<!--          <v-slide-x-transition mode="out-in">-->
            <router-view/>
<!--          </v-slide-x-transition>-->
        </v-main>
      </main-layout>
    </v-app>
  </div>
</template>

<script>
import AppEvents from './event'
import CookieService from '@/helpers/cookie'

import Confirm from '@/components/ui/Confirm'
import Alert from '@/components/ui/Alert'
import MainLayout from '@/layouts/MainLayout'
import Login from '@/views/Login'
import LoadingDialog from '@/components/ui/LoadingDialog.vue'
export default {
  name: 'App',
  components: { MainLayout, Login, LoadingDialog, Confirm, Alert },
  data: () => ({
    connected: false
  }),
  beforeUpdate () {
    this.initConfirm()
    window.getApp = this
  },
  created () {
    AppEvents.forEach(item => {
      this.$on(item.name, item.callback)
    })
    window.getApp = this
    this.initialization()
    console.log(this.$route.meta.layout)
  },
  mounted () {
    this.initAlert()
  },
  computed: {
    cuserID () {
      return this.$store.getters.currentID
    },
    isDebug () {
      return process.env.NODE_ENV === 'development'
    },
    // isBeta () {
    //   return this.$store.getters.betaMode
    // },
    isLogin () {
      return this.$store.getters.isLogin
    }
  },
  methods: {
    initConfirm (count = 0) {
      if (count === 30) return
      setTimeout(() => {
        if (this.$refs.confirm !== undefined) {
          this.$root.$confirm = this.$refs.confirm.show
        } else this.initConfirm(count + 1)
      }, 300)
    },
    initAlert (count = 0) {
      if (count === 10) return
      setTimeout(() => {
        if (this.$refs.alert !== undefined) {
          this.$root.$alert = this.$refs.alert.show
        } else this.initAlert(count++)
      }, 300)
    },
    async initialization1 () {
      this.initConfirm()
      console.info('Инициализация личного кабинета...')
      let initStart = new Date()
      this.initializationProcess = true
      let token = CookieService.getCookie('authToken')
      if (token && token.length > 0) {
        this.updateFcmToken()
      }
      if (token && token.length > 0 && !this.cuserID) {
        await this.$store.dispatch('createdUser')
      }
      if (this.cuserID) {
        this.$store.dispatch('loadEducationSpaces')
        this.$store.dispatch('loadMessageImportants').then(() => {
          this.dialogNotification.show = this.lstMessageImportants.length > 0
          this.dialogBlockedStudMigr = this.isBlockedMigrStud || this.isWarnMigrStud
        })
        this.checkMail()
        setTimeout(() => {
          this.initBitrix()
        }, 1000)
        this.$nextTick(() => {
          setTimeout(() => {
            this.loadFromLocalStorageStyleEye()
          }, 5000)
        })
      }
      await this.$store.dispatch('createdInitializationApplication')
      if (this.cuserID) {
        this.$store.dispatch('loadFeed')
        this.loadWebinars()
      }

      this.$store.commit('setBetaMode', localStorage.getItem('beta') === '1')
      this.connected = true
      let initDiff = Math.abs(new Date() - initStart)
      console.info('Личный кабинет инициализирован. Время инициализации - ' + initDiff + 'мс.')
      this.initializationProcess = false
    },
    async initialization () {
      this.initConfirm()
      console.info('Инициализация личного кабинета...')
      this.$store.dispatch('loadUser')
      await this.$store.dispatch('createdInitializationApplication')
      this.$store.commit('setBetaMode', localStorage.getItem('beta') === '1')
      setTimeout(() => {
        this.connected = true
      }, 200)
    },
  }
};
</script>
<style>
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,80);
.theme--light.v-data-table {
  color: rgba(0, 0, 0, 1) !important;
}
.v-application {
  /* font-family: 'Montserrat', sans-serif !important; */
  font-family: Golos,Open Sans,Arial,sans-serif !important;
}
.search-field {
  padding-top: 4px !important;
  max-width: 500px !important;
}
a {
  text-decoration: none;
}
/* .theme--light.v-application {
  background: rgb(36 58 81 / 5%) !important;
} */
</style>
