/*
    Методы для работы с API меню
*/
import api from "@/helpers/apiGet";
export default {
    async getMenuItems (typeID) {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/menu', { typeID: typeID})
        if (typeID && typeID === 1) return response.data
        let master = response.data.filter(f=>f.text === 'Master')[0]
        if (response.data.filter(f=>f.text === 'Контакты').length > 0) response.data.filter(f=>f.text === 'Контакты')[0].text = 'Реквизиты ВУЗа'
        return response.data.filter(f=>f.parentID === master.menuItemID).map(m=> { return { menuItemID: m.menuItemID, text: m.text, description: m.description, icon: m.linkImg, img: m.img, fullImg: true, href: this.updateLink(m.href) } }).filter(f=>f.href !== '')
    },
    async getDirectoryItems () {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/menu')
        let master = response.data.filter(f=>f.text === 'handbook')[0]
        return response.data.filter(f=>f.parentID === master.menuItemID).map(m=> { return { menuItemID: m.menuItemID, text: m.text, description: m.description, icon: m.linkImg, img: m.img, fullImg: true, href: this.updateLink(m.href) } }).filter(f=>f.href !== '')
    },
    async getDirectoryMenuItems () {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/menu')
        let master = response.data.filter(f=>f.text === 'MenuItems')[0]
        return response.data.filter(f=>f.parentID === master.menuItemID).map(m=> { return { menuItemID: m.menuItemID, text: m.text, description: m.description, icon: m.linkImg, img: m.img, fullImg: true, href: m.href } }).filter(f=>f.href !== '')
    },
    async getDirectoryMoresItems () {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/menu')
        let master = response.data.filter(f=>f.text === 'MoresItems')[0]
        return response.data.filter(f=>f.parentID === master.menuItemID).map(m=> { return { menuItemID: m.menuItemID, text: m.text, icon: m.linkImg, img: m.img, fullImg: true, href: m.href } }).filter(f=>f.href !== '')
    },
    updateLink (oldLink) {
        if(oldLink === '/Dek') {
            return '/Directory/Faculties'
        } else if(oldLink === '/Dek/Default.aspx?mode=spets') {
            return '/Directory/Specialties'
        } else if(oldLink === '/Nagr/Default.aspx') {
            return '/Directory/Teachers'
        }else if(oldLink === '/Dek/Default.aspx?mode=kaf') {
            return '/Directory/Departments'
        } else if(oldLink === '/Dek/Default.aspx?mode=group') {
            return '/Directory/Groups'
        } else if(oldLink === '/Plans') {
            return '/Plans'
        } else if(oldLink === '/Ved') {
            return '/Veds'
        } else if(oldLink === '/Stat/Debtors.aspx') {
            return '/Stat/Debts'
        } else if(oldLink === '/Contact/Contacts.aspx') {
            return '/Requisites'
        }  else if(oldLink === '/docu/default.aspx?page=1') {
            return '/Graphs'
        } else if(oldLink === '/Stat/Default.aspx?mode=statkaf') {
            return '/Statistics/k'
        } 
        else {
            return ''
        }
    }
}

