/*
    Методы для работы с API регистрации и авторизации
*/
import Api from '@/helpers/api'
import CookieService from '@/helpers/cookie'
import fingerprint from '@/helpers/fingerprint'
import Vue from 'vue'
export default {
  async getUserInfo () {
    await fingerprint.getFingerprint()
    return Api().get('/api/tokenauth')
      .then(response => {
        console.log(response)
        if (response.data.state === 1) {
          return response.data.data.user
        } else {
          return null
        }
      }).catch(async err => {
        if (err.request.status === 401) {
          let token = CookieService.getCookie('authToken')
          if (token) {
            console.log('Токен доступа устарел')
            let response = await Vue.prototype.$api.post('/api/Auth/UpdateToken', {}, null, true)
            if (response.data) {
              CookieService.setCookie('authToken', response.data.token, { path: '/' })
              CookieService.setCookie('refreshToken', response.data.refreshToken, { path: '/api/Auth/UpdateToken' })

              response = await Api().get('/api/tokenauth')
              if (response.data.state === 1) {
                return response.data.data.user
              } else {
                console.log(response)
                return null
              }
            } else {
              this.logOut()
            }
          }
        }
        console.log(err.request.status)
      })
  },
  updateToken () {

  },
  logOut () {
    CookieService.setCookie('authToken', '', { path: '/' })
    CookieService.setCookie('refreshToken', '', { path: '/api/Auth/UpdateToken' })
    Vue.prototype.$router.push('/login')
  },
  getToken () {
    return CookieService.getCookie('authToken')
  },
}
