<template>
  <!-- <main-page /> -->
  <!-- :is="'index'" -->
  <component :is="master">
  </component>
</template>

<script>
import Index from '../components/Index'
import MainPage from '../components/MainPage'
import MainStud from '../components/MainStud'
export default {
  name: 'Home',
  components: {
    MainPage, Index, MainStud
  },
  computed: {
    master () {
      // return 'MainStud'
      return this.initInfo && this.initInfo.masterMain ? this.initInfo.masterMain : 'index'
    },
    initInfo () {
      return this.$store.state.initInfo
    }
  }
}
</script>
