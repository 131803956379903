import CookieService from '@/helpers/cookie'
import Vue from 'vue'
import store from '@/store/index'
export default [
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function (e) {
      this.$router.push({ path: 'home' })
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function (e) {
      this.$root.$confirm({ text: 'Вы действительно хотите выйти?' }).then(result => {
        if (result) {
          localStorage.setItem('firebaseClientNotificationToken', '')
          this.$api.post('/api/auth/logout')
          localStorage.removeItem('authToken')
          CookieService.setCookie('authToken', '', { path: '/' })
          CookieService.setCookie('refreshToken', '', { path: '/api/Auth/UpdateToken' })
          this.$store.dispatch('destroyToken')
          this.$store.state.user = {}
          // this.$store.dispatch('updateVerificationType', 'Публикация')
          // this.$router.replace({ path: '/login' })
        }
      })

      // if (confirm('Вы действительно хотите выйти?')) {
      //   //console.log('hello1')
      //
      //   //Vue.prototype.$api.post('/api/auth/logout')
      //   //console.log('hello2')
      //
      //
      //   localStorage.removeItem('authToken')
      //   CookieService.setCookie('authToken', '', { path: '/' })
      //   CookieService.setCookie('refreshToken', '', { path: '/api/Auth/UpdateToken' })
      //   this.$store.dispatch('destroyToken')
      //   this.$store.state.user = {}
      //   this.$store.dispatch('updateVerificationType', 'Публикация')
      //
      //
      //   //console.log('hello3')
      //
      //   this.$router.replace({ path: '/login' })
      // } else {
      //
      // }
    }
  },
  {
    name: 'APP_PAGE_LOADED',
    callback: function (e) {
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function (e) {
      this.$router.push('/login')
      this.$message.error('Token has expired')
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg)
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg)
      this.$router.push('/forbidden')
    }
  },
  {
    name: 'APP_RESOURCE_DELETED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  },
  {
    name: 'APP_RESOURCE_UPDATED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg)
    }
  }

]
