export default {
    setCookie (name, value, options) {
      options = options || {}
  
      var expires = options.expires
  
      if (typeof expires === 'number' && expires) {
        var d = new Date()
        d.setTime(d.getTime() + expires * 1000)
        expires = options.expires = d
      }
      if (expires && expires.toUTCString) {
        options.expires = expires.toUTCString()
      }
      if (name === 'authToken') {
        let date = new Date()
        date.setDate(date.getDate() + 7)
        options.expires = date.toUTCString()
      }
  
      value = encodeURIComponent(value)
      var updatedCookie = name + '=' + value
  
      for (var propName in options) {
        updatedCookie += '; ' + propName
        var propValue = options[propName]
        if (propValue !== true) {
          updatedCookie += '=' + propValue
        }
      }
      if (name === 'authToken' && document.URL.toLocaleLowerCase().startsWith('https://')) {
        updatedCookie += ';secure;'
      }
  
      document.cookie = updatedCookie
    },
    getCookie (name) {
      var matches = document.cookie.match(new RegExp(
        '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
      ))
      return matches ? decodeURIComponent(matches[1]) : undefined
    }
  }
  