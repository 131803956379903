/*
    Методы для работы с API новостей ИС
*/
import api from "@/helpers/apiGet";
export default {
    async getNews () {
        // TODO: Тут будет запрос к API
        let response = await api.get('/api/NewsEios/news')
        return response.data
    }
}
