import axios from 'axios'
import Vue from 'vue'
import store from '@/store/index'
import CookieService from '@/helpers/cookie'
export default () => {
  // let cookieToken = CookieService.getCookie('authToken')
  // console.log(cookieToken)
  // if(cookieToken === undefined){
  //   if(localStorage.authToken != undefined){
  //     CookieService.setCookie = localStorage.authToken
  //   }
  // }
  let axi = axios.create({
    baseURL: 'https://api.iprbooks.ru',
    withCredentials: false,
    'headers': {
      'Accept': 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      'X-APIKey': 'pG7xLieVraKMHksK',
      'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGci...OjU4MTMsInRpbWUiOjE1Mjc1MTA2OTYOjU4MTM',
      'client_id': '8616',
      'Referrer':"no-referrer"

    }
  })
  axi.interceptors.response.use(null, (err) => {

    if (err.response !== undefined && err.response.status !== undefined) {
      Vue.notify({
        group: 'error',
        title: 'Ошибка!',
        text: 'Ошибка запроса к API IPR Books',
        type: 'error'
      })
      // alert('Ошибка')
    }

    if (err.response === null) {
    }
    if (err.response !== undefined && err.response.status !== undefined && err.response.status === 401) {
      store.commit('setIsNotAuthorized', err.message)
      //CookieService.setCookie('authToken', '', { path: '/' })
      // window.location = ''
    }
    return Promise.reject(err)
  })
  return axi
}
