import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import vueHeadful from 'vue-headful'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import global from './global'
import Axios from "axios";
import config from "@/helpers/config";

import common from '@/helpers/common'
import apiGetService from '@/helpers/apiGet'
import iprapiGetService from '@/helpers/iprapiGet'
import lang from '@/helpers/lang'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueYandexMetrika from 'vue-yandex-metrika'

Vue.config.productionTip = false
var now = new Date()
Vue.use(global)
Vue.component('vue-headful', vueHeadful)
Vue.prototype.$http = Axios
Vue.prototype.$api = apiGetService
Vue.prototype.$iprapi = iprapiGetService
Vue.prototype.$service = common
Vue.prototype.$requestsHistory = []
Vue.prototype.$rwl = lang.replaceWrongLayout // rwl means "replace wrong layout" maybe later we can change naming
Vue.prototype.$rwlSearch = lang.searchWithRWL
Vue.prototype.$cParams = {
  allowSnowMode: false,
  happyNewYear: !!(new Date(new Date().getFullYear() + '-12-20') <= now || now <= new Date((new Date().getFullYear()) + '-01-14'))
}

Axios.get('/config.json?t=' + new Date().getSeconds()).then(response => {
  Vue.prototype.$api.address = response.data.api_address
  Vue.prototype.$startPage = response.data.start_page
  if (response.data.recaptcha_site_key) {
    Vue.use(VueReCaptcha, { siteKey: response.data.recaptcha_site_key, loaderOptions: { autoHideBadge: true } })
  }
  if (response.data.yandex_metrika_id) {
    Vue.use(VueYandexMetrika, {
      id: response.data.yandex_metrika_id,
      router: router,
      env: process.env.NODE_ENV
    })
  }
  Vue.prototype.$firebaseConfig = response.data.firebaseConfig
  StartApp()
}).catch(() => {
  Vue.prototype.$api.address = '/'
  StartApp()
})

let disableAnimation = (localStorage.getItem('disableAnimations') || 'false') === 'true'
if (disableAnimation) {
  const div = {
    functional: true,
    render: (h, { data, children }) => h('div', { ...data }, children)
  }

  Vue.component('transition', div)
  Vue.component('transition-group', div)
}

// eslint-disable-next-line no-extend-native
Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + (h * 60 * 60 * 1000))
  return this
}
// I Love C# LINQ ♡
// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function () {
  const onlyUnique = (value, index, self) => {
    return self.findIndex(i => i === value) === index
  }
  return this.filter(onlyUnique)
}

// Старт приложения
let currentVersion = document.documentElement.dataset.buildTimestampUtc

function StartApp () {
  console.info('Версия: ', currentVersion)
  let version = localStorage.getItem('version')
  if (version !== currentVersion) {
    console.info('Обновление на ' + currentVersion)
    localStorage.setItem('version', currentVersion)
    if (process.env.NODE_ENV !== 'development') {
      window.location.reload(true)
    }
  }
  window.Bus = new Vue()
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
  }).$mount('#app')
}


