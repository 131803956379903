import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store/index'
import Api from '@/helpers/apiGet'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
const MainPage = () => import(/* webpackChunkName: "base" */ '../components/MainPage')
const CorporatePortal = () => import(/* webpackChunkName: "base" */ '../components/CorporatePortal')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false }
  },
  {
    path: '/MainPage',
    name: 'MainPage',
    component: MainPage,
    meta: { requiresAuth: false, layout: 'empty' }
  },
  {
    path: '/CorporatePortal',
    name: 'CorporatePortal',
    component: CorporatePortal,
    meta: { requiresAuth: true, layout: 'empty' }
  },
  {
    path: '/www/:site',
    beforeEnter (to, from, next) {
      window.open('https://' + to.params.site, '_blank')
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { layout: 'empty' }
  },
  {
    path: '/Directory/Departments/:id?',
    name: 'Departments',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Departments.vue')
  },
  {
    path: '/Admin/SMTP',
    name: 'SMTP',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/admin/SMTP.vue')
  },
  {
    path: '/Directory/Faculties/:id?',
    name: 'Faculties',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Faculties.vue')
  },
  {
    path: '/Directory/Specialties',
    name: 'Specialties',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Specialties.vue')
  },
  {
    path: '/Directory/Teachers',
    name: 'Teachers',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Teachers.vue')
  },
  {
    //path: '/Directory/GroupsOn',
    path: '/Directory/Groups/:mode?/:id?',
    name: 'Groups',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Groups.vue')
  },
  {
    path: '/Directory/Students/:groupID',
    name: 'Students',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Students.vue')
  },
  {
    path: '/Debt/DebtByStudent/:groupID/:sem/:studId',
    name: 'DebtByStudent',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/debt/DebtByStudent.vue')
  },
  {
    //path: '/Directory/GroupsOn',
    path: '/Directory/Nagr/:groupid?/:selyear?/:prepid?/:kafid?',
    name: 'Nagr',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/directory/Nagr.vue')
  },
  {
    path: '/Stat/Debts',
    name: 'Debts',
    component: () => import(/* webpackChunkName: "Directory" */ '../views/stat/Debts.vue')
  },
  {
    path: '/Setup',
    name: 'Setup',
    component: () => import(/* webpackChunkName: "Setup" */ '../views/Setup.vue')
  },
  {
    path: '/Plans',
    name: 'Plans',
    component: () => import(/* webpackChunkName: "Plans" */ '../views/plans/PlansList.vue')
  },
  {
    path: '/Plans/:planID',
    name: 'PlanOpen',
    component: () => import(/* webpackChunkName: "Plans" */ '../views/plans/PlanOpen.vue')
  },
  {
    path: '/Veds/:groupID?',
    name: 'Veds',
    component: () => import(/* webpackChunkName: "Veds" */ '../views/veds/VedsList.vue')
  },
  {
    path: '/Veds/:groupID/:vedID',
    name: 'VedsOpen',
    component: () => import(/* webpackChunkName: "Veds" */ '../views/veds/VedsOpen.vue')
  },
  {
    path: '/SummaryVeds/:groupID',
    name: 'SummaryVeds',
    component: () => import(/* webpackChunkName: "SummaryVeds" */ '../views/veds/SummaryVeds.vue')
  },
  {
    path: '/Statistics/:mode',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/stat/Statistics.vue')
  },
  {
    path: '/DisciplineStatistics/:mode?/:year?/:sem?/:stract?/:id?',
    name: 'DisciplineStatistics',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/stat/DisciplineStatistics.vue')
  },
  {
    path: '/Stat/StudentsDebts/:group?/:sem?/:mode?',
    name: 'DisciplineStatistics',
    component: () => import(/* webpackChunkName: "Statistics" */ '../views/stat/StudentsDebts.vue')
  },
  {
    path: '/Graphs/',
    name: 'GraphsList',
    component: () => import(/* webpackChunkName: "Graphs" */ '../views/graphs/GraphsList.vue')
  },
  {
    path: '/Graphs/:groupID/:semester',
    name: 'GraphOpen',
    component: () => import(/* webpackChunkName: "Graphs" */ '../views/graphs/GraphOpen.vue')
  },
  {
    path: '/Graphs/:groupID',
    name: 'GraphOpen',
    component: () => import(/* webpackChunkName: "Graphs" */ '../views/graphs/GraphOpen.vue')
  },
  {
    path: '/Requisites',
    name: 'Requisites',
    component: () => import(/* webpackChunkName: "Veds" */ '../views/requisites/Requisites.vue')
  },
  {
    path: '/PersonalDataProcessingPolicy',
    name: 'PersonalDataProcessingPolicy',
    component: () => import(/* webpackChunkName: "Veds" */ '../views/requisites/PersonalDataProcessingPolicy.vue')
  },
  {
    path: '/TermsOfUse',
    name: 'TermsOfUse',
    component: () => import(/* webpackChunkName: "Veds" */ '../views/requisites/TermsOfUse.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]
const router = new VueRouter({
  routes
})
const openRoutes = ['Login']

const PAGES_TITLE = {
  'journalList': 'Список журналов',
  'journal': 'Журнал',
  'admin': 'Администрирование',
  'Users': 'Список пользователей',
  'Menu': 'Настройка меню',
  'Rasp': 'Расписание',
  'RaspList': 'Расписание',
  'RaspManager': 'Расписание',
  'studentPasses': 'Пропуски студента',
  'techWorks': 'Тех. работы',
  'PassPermits': 'Статистика по пропускам',
  'ZachBook': 'Зачётная книжка',
  'EduStatistics': 'Учебная деятельность',
  'searchStudents': 'Портфолио обучающихся',
  'portfolioListWorksVerifier': 'Верификация',
  'portfolioListWorks': 'Портфолио',
  'staffRatingTable': 'Рейтинг преподавателя',
  'studentOfTheYearList': 'Список заявок',
  'studentOfTheYear': 'Лучший студент',
  'journalSettings': 'Настройка журналов',
  'questionnairesEditForm': 'Редактирование',
  'questionnariesList': 'Анкетирование',
  'reviewsList': 'Список отзывов',
  'workPortfolio': 'Портфолио',
  'journalTeachersRating': 'Рейтинг преподавателей',
  'journalTeacherReviews': 'Отзывы о преподавателе',
  'Search': 'Поиск',
  'EffectiveContract': 'Эффективный контракт',
  'certificatesOrdering': 'Заказ справок',
  'Mail': 'Почта',
  'SchoolXFeedbackOverview': 'Обратная связь',
  'SchoolXFeedbackReflection': 'Рефлексия',
  'SchoolXFeedbackTutorial': 'Тьюториал',
  'SchoolXFeedbackModules': 'Оценка модулей',
  'SchoolXFeedbackPeerReview': 'PeerReview',
  'SchoolXFeedbackSettings': 'Обратная связь (настройки)',
  'ChoiceElectives': 'Статистика по модулям',
  'GIAProtocols': 'Список протоколов',
  'GIAProtocol': 'Протокол',
  'PointsSettings': 'Настройка баллов',
  'ChatList': 'Чаты',
  'communications': 'Мессенджер',
  'communication': 'Мессенджер',
  'ratingAll': 'Рейтинг обучающихся',
  'feedback': 'Обратная связь',
  'Feed': 'Лента',
  'electronicEducationListCourse': 'Курсы',
  'trajectory': 'Траектория',
  'EducationalChart': 'Учебный график',
  'SilabusList': 'Модули',
  'Silabus': 'Модуль',
  'Attendance': 'Статистика посещаемости',
  'AcademicPerformance': 'Статистика по текущей успеваемости',
  'BoardList': 'Список досок',
  'webinarsList': 'Список вебинаров',
  'VacanciesList': 'Список вакансий',
  'eventsCalendar': 'Календарь мероприятий',
  'RaspManagerCalendar': 'Расписание',
  'ErrorsLog': 'Ошибки',
  'schoolXStudentsAttendance': 'Посещаемость',
  'schoolXStudentsAcademicPerformance': 'Промежуточная успеваемость',
  'SchoolXFeedbackStat': 'Обратная связь (статистика)',
}

const PAGES_ICONS = {
  'Rasp': 'calendar-regular',
  'RaspList': 'calendar-regular',
  'RaspManager': 'calendar-regular',
  'ChatList': 'message',
  'communications': 'message',
  'communication': 'message',
  'trajectory': 'modules',
  'EducationalChart': 'book',
  'SilabusList': 'modules',
  'Silabus': 'modules'
}
// router.beforeResolve((to, from, next) => {
//   // If this isn't an initial page load.
//   if (to.name) {
//       // Start the route progress bar.
//       NProgress.start()
//   }
//   next()
// })
router.beforeEach((to, from, next) => {
  localStorage.setItem('previousRoutePath', from.path)
  store.commit('updateRouterHistory', from)
  let title = PAGES_TITLE[to.name]
  let icon = PAGES_ICONS[to.name]
  if (icon != null) {
    store.dispatch('currentIconPage', icon)
  } else {
    store.dispatch('currentIconPage', null)
  }
  if (title != null) {
    window.document.title = title
    store.dispatch('currentTitlePage', title)
  } else {
    window.document.title = 'Личный кабинет'
    store.dispatch('currentTitlePage', 'Личный кабинет')
  }
  let disableProgressRoute = ['/EventsCalendar', '/Projects/Boards/']
  if(!disableProgressRoute.filter(f=> to.path.toLowerCase().startsWith(f.toLowerCase())).length > 0) {
    NProgress.start()

  }
  next()
  if (openRoutes.includes(to.name) !== 'Login') {
    store.dispatch('selectedCategoryTitle', null)
    next()
  } else if (store.getters.isLogin) {
    next()
  } else {
    next('/login')
  }
})

router.afterEach((to, from) => {
  NProgress.done()
})

export default router
