import Vue from 'vue'
import cookie from '@/helpers/cookie'

export default {
  checkFingerprint () {
    let fingerprint = localStorage.getItem('fingerprint') || cookie.getCookie('fingerprint')
    if (fingerprint === 'undefined' || fingerprint === 'null') {
      fingerprint = ''
    }
    localStorage.setItem('fingerprint', fingerprint)
    cookie.setCookie('fingerprint', fingerprint)
    sessionStorage.setItem('fp', fingerprint)
  },
  async getFingerprint () {
    let fingerprint = localStorage.getItem('fingerprint') || cookie.getCookie('fingerprint')
    if (fingerprint === 'undefined' || fingerprint === 'null') {
      fingerprint = ''
    }
    if (!fingerprint) {
      try {
        let response = await Vue.prototype.$api.get('/api/UserInfo/Devices/RandomIdentity')
        fingerprint = response.data.randomIdentity
      } catch {}
    }
    localStorage.setItem('fingerprint', fingerprint)
    cookie.setCookie('fingerprint', fingerprint)
    sessionStorage.setItem('fp', fingerprint)
    return fingerprint // fingerprint
  }
}
