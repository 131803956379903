<template>
  <div>
    <v-card color="stud lighten-2" class="pa-4 white--text" style="top: -30px">
      <span class="display-0 font-weight-bold" style="font-size: 15pt;"><v-icon class="mb-1" color="white" left>mdi-account</v-icon> {{ fPass ? 'Восстановаление пароля' : 'Регистрация' }}</span>
    </v-card>
    <v-card-text class="pt-0">
      <div>
        <v-form ref="formLogin" v-model="valid" lazy-validation >
          <v-expand-transition>
            <div v-if="extended">
              <v-text-field
                color="stud"
                append-icon="mdi-account"
                name="login"
                label="Фамилия"
                placeholder="Введите фамилию"
                type="text"
                v-model="lastname"
              />
              <v-text-field
                color="stud"
                append-icon="mdi-account"
                name="login"
                label="Имя"
                placeholder="Введите имя"
                type="text"
                v-model="firstname"

              />
              <v-text-field
                color="stud"
                append-icon="mdi-account"
                name="login"
                label="Отчество"
                placeholder="Введите отчество"
                type="text"
                v-model="middlename"
              />
              <v-text-field
                color="stud"
                append-icon="mdi-account-card-details"
                name="login"
                label="Номер зачётной книжки"
                placeholder="Введите номер ЗК"
                type="text"
                v-model="recordbookNumber"
              />
            </div>
          </v-expand-transition>
          <v-text-field
            color="stud"
            append-icon="mdi-at"
            name="login"
            label="Электронная почта (E-Mail)"
            placeholder="Введите E-Mail"
            type="text"
            :rules="!extended ? [rules.required, rules.email] : []"
            @input="email ? email = email.replaceAll(' ', ''): null"
            v-model="email"
            @keydown="getSubmit($event)"
          />
          <v-expand-transition>
            <v-layout v-if="error">
              <span style="color: red;"> {{error}}</span>
            </v-layout>
          </v-expand-transition>

          <v-layout wrap class="mx-0">
            <v-btn small rounded class="white--text mt-3" color="stud" @click="error = null, $emit('changeMode')"> <v-icon small left>mdi-arrow-left</v-icon> Назад</v-btn>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" :disabled="!valid" small rounded class="white--text mt-3" color="stud" @click="register"> {{fPass ? 'Восстановить' : 'Зарегистрироваться'}}</v-btn>
          </v-layout>
        </v-form>
      </div>
    </v-card-text>
  </div>
</template>

<script>
export default {
  name: 'RegistrationForm',
  props: ['forParentMode', 'extendedRegistration', 'fPass'],
  data () {
    return {
      valid: true,
      rules: {
        required: value => !!value || '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || ''
        }
      },
      lastname: null,
      firstname: null,
      middlename: null,
      recordbookNumber: null,
      extended: false,
      email: null,
      error: null,
      loading: false
    }
  },
  methods: {
    getSubmit (value) {
      if ((value.keyCode === 32)) {
        return value.preventDefault()
      }
    },
    test () {
      this.$emit('changeMode', 'Тестовое сообщение')
    },
    async register () {
      this.error = null
      if (!this.extended ? this.$refs.formLogin.validate() : true) {
        this.loading = true
        let response = await this.$api.post('/api/auth/Registration', { email: this.email, lastname: this.lastname, firstname: this.firstname, middlename: this.middlename, recordbookNumber: this.recordbookNumber, isParent: this.forParentMode || false, isRecovery: this.fPass })

        if (response.state === 1) {
          this.$emit('changeMode', response.msg)
        } else {
          this.error = response.msg
          this.loading = false
        }
      }
    }
  },
  mounted () {
    this.loading = false
    if ((this.forParentMode && this.forParentMode === true) || this.fPass === true) {
      this.extended = false
    } else {
      this.extended = this.extendedRegistration
    }
  }
}
</script>

<style scoped>

</style>
