import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import Api from '@/helpers/api'
import Login from "@/helpers/api/login";
import CookieService from '@/helpers/cookie'
import common from '@/helpers/common'
Vue.use(Vuex)
let saveYearCache = (year) => {
  sessionStorage.setItem('sYear', year)
}
let getYearCache = () => {
  let year = sessionStorage.getItem('sYear')
  if (!year || year.length !== 9) {
    year = common.getCurrentYear(8)
  }
  return year
}

const nirWorksModule = {
  state: {
    nirWorks: {
      workID: 0, // Код - тип Int
      status: Number, // Статус - тип Int
      typeID: Number, // КодТипа - тип Int
      underTypeID: Number, // КодПодТипа - тип Int
      ugs: '', // УГС - тип String
      name: '', // Наименование - тип String
      compilation: '', // Сборник - тип String
      publishing: '', // Издательство - тип String
      date: Date, // Дата - тип DateTime
      dateFinish: Date, // ДатаОкончания - тип DateTime
      year: Number, // Год - тип Int
      volumeCollection: '', // ОбъемСборника - тип float
      volumeWork: '', // ОбъемРаботы - тип float
      link: '', // Ссылка - тип String
      address: '', // Адресс - тип String
      authors: '', // Авторы - тип String
      description: '', // Описание - тип String
      attr1: '', // Атрибут1 - тип String
      attr2: '', // Атрибут2 - тип String
      attr3: '', // Атрибут3 - тип String
      attr4: '', // Атрибут4 - тип String
      attr5: Number, // Атрибут5 - тип Int
      attr6: Number, // Атрибут6 - тип Int
      attr7: Number, // Атрибут7 - тип Int
      isDelete: Boolean, // Удалена - тип Boolean  ?
      list: Number, // Список - тип Int16   ?
      list1: Number, // Список 1 - тип Int16   ?
      list2: Number, // Список 2 - тип Int16   ?
      list3: Number, // Список 3 - тип Int16   ?
      list4: Number, // Список 4 - тип Int16   ?
      dateInsert: Date, // ДатаВнесения - тип DateTime
      idUser: Number, // КодВнесшего - тип Int    ?
      hash: '', // Hash - тип String
      idParent: Number, // КодРодителя - тип Int  ?
      yearO: Number, // ГодО - тип Int
      monthO: Number, // МесяцО - тип Int
      idMARK: Number, // КодИзМАРК - тип Int   ?
      mark: Number, // Оценка - тип Double
      maxMark: Number, // МаксимальнаяОценка - тип Double
      listPlace: Number, // СписокМесто - тип Int16   ?
      ekRegin: '', // ЭКОбласть - тип String
      ekTarget: '', // ЭКЦель - тип String
      ekAnotation: '', // ЭКАннотация - тип String
      ekStatus: Number, // ЭКСтатус - тип Int
      grnti: '', // ГРНТИ - тип String
      language: Number, // Язык - тип Int
      scienceField: Number, // ОбластьНауки - тип Int
      rName: '', // РНаименование - тип String
      rekRegin: '', // РЭКОбласть - тип String
      rekTarget: '', // РЭКЦель - тип String
      rekAnotation: '', // РЭКАннотация - тип String
      statusConclusion: Number, // СтатусЗаключения - тип Int  ?
      statusAct: Number, // СтатусАкта - тип Int  ?
      secretMaterial: false,
      lstControlledMaterials: false,
      readyDocuments: false, // Готовые документы для экспорта
      // Библиотека
      wos: null,
      scopus: null,
      vac: false,
      rinc: false,
      strGRNTI: '', // ГРНТИ
      strYGNP: '', // УГНП
      objectYGNP: Object,
      date1: Date,
      date2: Date,
      ballOfWork: Number, // баллы за работу
      lstIndex: Array, // Индексация
      doiWos: '', // Doi wos
      doiScopus: '', // Doi scopus
      keywords: '', // ключевые слова
      // printedPageMaterial: '',
      pageMaterialFrom: Number, // страницы с
      pageMaterialBy: Number // страницы по
    },
    nirWorksView: {}, // загружаем объект из апи
    remarks: [], // массив для замечаний
    experts: [], // массив для экспертов
    formsFilePublication: [ // массив для файлов скан-версию публикации
      {
        'choised': false,
        'load': false
      }
    ],
    formsFileLiterature: [ // массив для файлов списка литературы
      {
        'choised': false,
        'load': false
      }
    ],
    formsFile: [],
    // formsFile: [ // массив для файлов подтверждающих документов
    //   {
    //     'choised': false,
    //     'load': false
    //   }
    // ],
    DefaultFormsFile: [ // массив для файлов подтверждающих документов
      {
        'choised': false,
        'load': false
      }
    ],
    arrayType: [{
      'name': '',
      'type': Number
    }], // массив для выставки
    DefaultArrayType: [{
      'name': '',
      'type': Number
    }],
    initScienceInfo: {
      'included': true,
      'techWorks': false,
      'techWorksMessage': null
    },
    allowEdit: false // Редактирование доступно, если это главный автор
  },
  mutations: {
    createdNirWorksView (state, playload) {
      state.nirWorksView = playload
    },
    getRemarks (state, playload) {
      state.remarks = playload
    },
    createdNirWorksId (state, playload) {
      state.nirWorks = playload.nirWork
      state.nirWorksView = playload.nirWorkView
      authorsModule.state.authors = playload.authors
      state.remarks = playload.remarks
      state.experts = playload.experts
      state.allowEdit = playload.allowEdit
    },
    createdNirWorksIdView (state, playload) {
      state.nirWorksView = playload
    },
    updateStatus (state, playload) {
      // Статус - тип Int
      state.nirWorks.status = playload
    },
    updateStatusAct (state, playload) {
      // Статус Акта - тип Int
      state.nirWorks.statusAct = playload
    },
    updatestrСatalog (state, playload) {
      state.nirWorks.strYGNP = playload.nirWork.strYGNP
      state.nirWorks.strGRNTI = playload.nirWork.strGRNTI
      state.nirWorks.status = playload.nirWork.status
      publicationModule.state.infoFileLst = playload.filesList
    },
    // Добавляет форму для файлов
    addFormFile (state, playload) {
      state.formsFile.push({
        ...playload,
        'choised': false,
        'load': false
      })
    },
    REMOVE_ITEM_FILE (state, playload) {
      state.formsFile.splice(state.formsFile.indexOf(state.formsFile[playload.index]), 1)
    },
    // Добавляет форму для файлов скан версии публикации
    addFormFilePublication (state) {
      state.formsFilePublication.push({
        'choised': false,
        'load': false
      })
    },
    // Добавляет форму для файлов скан версии публикации
    addFormFileLiterature (state) {
      state.formsFileLiterature.push({
        'choised': false,
        'load': false
      })
    },
    // Добавляет поля для выставки
    addPoleVistavka (state) {
      state.arrayType.push({
        'name': '',
        'type': Number
      })
    },
    REMOVE_ITEM_Vistavka (state, playload) {
      state.arrayType.splice(state.arrayType.indexOf(state.arrayType[playload.index]), 1)
    },
    // обновление для подтверждающего документа
    updateChoisedFile (state, playload) {
      state.formsFile[playload.index].choised = playload.data
    },
    // Обновление для файлов скан версии публикации
    updateChoisedFilePublication (state, playload) {
      state.formsFilePublication.choised = playload
    },
    // Обновление для файлов списка литературы
    updateChoisedFileLiterature (state, playload) {
      state.formsFileLiterature.choised = playload
    },
    // updateChoisedFileReadyDocs (state, playload) {
    //   state.formsFileReadyDocs.choised = playload
    // },
    updateInformationWorks (state, playload) {
      // мутация для обновления данных из обьекта
      if (playload.name === 'ugs') {
        state.nirWorks.ugs = playload.data
      }
      if (playload.name === 'underTypeID') {
        state.nirWorks.underTypeID = playload.data
      }
      if (playload.name === 'name') {
        state.nirWorks.name = playload.data
      }
      if (playload.name === 'compilation') {
        state.nirWorks.compilation = playload.data
      }
      if (playload.name === 'publishing') {
        state.nirWorks.publishing = playload.data
      }
      if (playload.name === 'date') {
        state.nirWorks.date = playload.data
      }
      if (playload.name === 'date1') {
        state.nirWorks.date1 = playload.data
      }
      if (playload.name === 'date2') {
        state.nirWorks.date2 = playload.data
      }
      if (playload.name === 'dateInsert') {
        state.nirWorks.dateInsert = playload.data
      }
      if (playload.name === 'dateFinish') {
        state.nirWorks.dateFinish = playload.data
      }
      if (playload.name === 'year') {
        state.nirWorks.year = playload.data
      }
      if (playload.name === 'volumeCollection') {
        state.nirWorks.volumeCollection = playload.data
      }
      if (playload.name === 'volumeWork') {
        state.nirWorks.volumeWork = playload.data
      }
      if (playload.name === 'link') {
        state.nirWorks.link = playload.data
      }
      if (playload.name === 'address') {
        state.nirWorks.address = playload.data
      }
      if (playload.name === 'authors') {
        state.nirWorks.authors = playload.data
      }
      if (playload.name === 'description') {
        state.nirWorks.description = playload.data
      }
      if (playload.name === 'attr1') {
        state.nirWorks.attr1 = playload.data
      }
      if (playload.name === 'attr2') {
        state.nirWorks.attr2 = playload.data
      }
      if (playload.name === 'attr3') {
        state.nirWorks.attr3 = playload.data
      }
      if (playload.name === 'attr4') {
        state.nirWorks.attr4 = playload.data
      }
      if (playload.name === 'attr5') {
        state.nirWorks.attr5 = playload.data
      }
      if (playload.name === 'attr6') {
        state.nirWorks.attr6 = playload.data
      }
      if (playload.name === 'attr7') {
        state.nirWorks.attr7 = playload.data
      }
      if (playload.name === 'isDelete') {
        state.nirWorks.isDelete = playload.data
      }
      if (playload.name === 'list') {
        state.nirWorks.list = playload.data
      }
      if (playload.name === 'list1') {
        state.nirWorks.list1 = playload.data
      }
      if (playload.name === 'list2') {
        state.nirWorks.list2 = playload.data
      }
      if (playload.name === 'list3') {
        state.nirWorks.list3 = playload.data
      }
      if (playload.name === 'list4') {
        state.nirWorks.list4 = playload.data
      }
      if (playload.name === 'dateInsert') {
        state.nirWorks.dateInsert = playload.data
      }
      if (playload.name === 'hash') {
        state.nirWorks.hash = playload.data
      }
      if (playload.name === 'yearO') {
        state.nirWorks.yearO = playload.data
      }
      if (playload.name === 'monthO') {
        state.nirWorks.monthO = playload.data
      }
      if (playload.name === 'idMARK') {
        state.nirWorks.idMARK = playload.data
      }
      if (playload.name === 'mark') {
        state.nirWorks.mark = playload.data
      }
      if (playload.name === 'maxMark') {
        state.nirWorks.maxMark = playload.data
      }
      if (playload.name === 'listPlace') {
        state.nirWorks.listPlace = playload.data
      }
      if (playload.name === 'ekRegin') {
        state.nirWorks.ekRegin = playload.data
      }
      if (playload.name === 'ekTarget') {
        state.nirWorks.ekTarget = playload.data
      }
      if (playload.name === 'ekAnotation') {
        state.nirWorks.ekAnotation = playload.data
      }
      if (playload.name === 'ekStatus') {
        state.nirWorks.ekStatus = playload.data
      }
      if (playload.name === 'language') {
        state.nirWorks.language = playload.data
      }
      if (playload.name === 'scienceField') {
        state.nirWorks.scienceField = playload.data
      }
      if (playload.name === 'rName') {
        state.nirWorks.rName = playload.data
      }
      if (playload.name === 'rekRegin') {
        state.nirWorks.rekRegin = playload.data
      }
      if (playload.name === 'rekTarget') {
        state.nirWorks.rekTarget = playload.data
      }
      if (playload.name === 'rekAnotation') {
        state.nirWorks.rekAnotation = playload.data
      }
      if (playload.name === 'secretMaterial') {
        state.nirWorks.secretMaterial = playload.data
      }
      if (playload.name === 'lstControlledMaterials') {
        state.nirWorks.lstControlledMaterials = playload.data
      }
      // list
      if (playload.name === 'list') {
        state.nirWorks.list = playload.data
      }
      // Тип публикации
      if (playload.name === 'typeID') {
        state.nirWorks.typeID = playload.data
      }
      // Материал
      if (playload.name === 'vac') {
        state.nirWorks.vac = playload.data
      }
      if (playload.name === 'rinc') {
        state.nirWorks.rinc = playload.data
      }
      if (playload.name === 'scopus') {
        state.nirWorks.scopus = playload.data
      }
      if (playload.name === 'wos') {
        state.nirWorks.wos = playload.data
      }
      if (playload.name === 'grnti') {
        state.nirWorks.grnti = playload.data
      }
      if (playload.name === 'listVistavka') {
        state.arrayType[playload.index].type = playload.data
      }
      if (playload.name === 'nameVistavka') {
        state.arrayType[playload.index].name = playload.data
      }
      if (playload.name === 'readyDocuments') {
        state.nirWorks.readyDocuments = playload.data
      }
      if (playload.name === 'ballOfWork') {
        state.nirWorks.ballOfWork = playload.data
      }
      if (playload.name === 'lstIndex') {
        state.nirWorks.lstIndex = playload.data
      }
      if (playload.name === 'doiWos') {
        state.nirWorks.doiWos = playload.data
      }
      if (playload.name === 'doiScopus') {
        state.nirWorks.doiScopus = playload.data
      }
      if (playload.name === 'keywords') {
        state.nirWorks.keywords = playload.data
      }
      // if (playload.name === 'printedPageMaterial') {
      //   state.nirWorks.printedPageMaterial = playload.data
      // }
      if (playload.name === 'pageMaterialFrom') {
        state.nirWorks.pageMaterialFrom = playload.data
      }
      if (playload.name === 'pageMaterialBy') {
        state.nirWorks.pageMaterialBy = playload.data
      }
    }
  },
  actions: {
    // При создании сторейджа, начальная инциация
    async createdPoles (context, playload) {
      context.commit('createdNirWorksView', await Publication.getWorksView(playload.typeID, playload.podTip))
    },
    async getRemarks ({ commit }, playload) {
      commit('getRemarks', await Publication.getWorksIdRemarks(playload))
    },
    async createdNirWorksId (context, playload) {
      // let Myheaders = new Headers([
      //   ['Accept', 'application/json'],
      //   ['Content-Type', 'application/json'],
      //   ['Authorization', 'Bearer ' + localStorage.authToken]
      // ])
      //  await fetch('http://localhost:8089/api/Publication/Get/Work?workID=' + playload, {method: 'GET', headers: Myheaders })
      //  .then((response) => response.json())
      //  .then((responseJSON) => {
      //   console.log("Im here",responseJSON)
      //    context.commit('createdNirWorksId', responseJSON.data)

      //  })
      await Publication.getWorksId(playload).then(response => {
        context.commit('createdNirWorksId', response)
      })

      // commit('createdNirWorksId', await Publication.getWorksId(playload))
    },
    async createdNirWorksIdView ({ commit }, playload) {
      commit('createdNirWorksIdView', await Publication.getWorksIdView(playload))
    },
    async updateStatus (context, playload) {
      context.commit('updateStatus', await Publication.getWorksIdStatus(playload))
    },
    async updateStatusAct (context, playload) {
      context.commit('updateStatusAct', await Publication.getWorksIdStatusAct(playload))
    },
    // Справочники
    async updatestrСatalog (context, playload) {
      context.commit('updatestrСatalog', await Publication.getWorksIdCatalog(playload))
    },
    // Обновление полей
    updateInformationWorks (context, playload) {
      context.commit('updateInformationWorks', playload)
    },
    // Добавление потвержд. док. файлов
    addFormFile (context, playload) {
      context.commit('addFormFile', playload)
    },
    // Добавление файлов для скан версии публикации
    addFormFilePublication (context, playload) {
      context.commit('addFormFilePublication', playload)
    },
    // Добавление файлов для списка литературы
    addFormFileLiterature (context, playload) {
      context.commit('addFormFileLiterature', playload)
    },
    addPoleVistavka (context, playload) {
      context.commit('addPoleVistavka', playload)
    },
    async updateChoisedFile (context, playload) {
      context.commit('updateChoisedFile', playload)
    },
    async updateChoisedFilePublication (context, playload) {
      context.commit('updateChoisedFilePublication', playload)
    },
    async updateChoisedFileLiterature (context, playload) {
      context.commit('updateChoisedFileLiterature', playload)
    },
    // async updateChoisedFileReadyDocs (context, playload) {
    //   context.commit('updateChoisedFileReadyDocs', playload)
    // },
    REMOVE_ITEM_Vistavka (context, playload) {
      context.commit('REMOVE_ITEM_Vistavka', playload)
    },
    REMOVE_ITEM_FILE (context, playload) {
      context.commit('REMOVE_ITEM_FILE', playload)
    }
  },
  getters: {

  }

}

export default new Vuex.Store({
  modules: {
    nirWorksModule,
    // ChatsModule,
    // ContestsModule,
    // MenuModule,
    // FeedModule
  },
  state: {
    betaMode: false,
    sYear: getYearCache(), // '2021-2022', //Vue.prototype.$service.getCurrentYear(8),
    years: common.getYearsList(2019, null, new Date().getMonth() < 8), // Vue.prototype.$service.getYearsList(2019),
    shortName: '',
    routerHistory: [],
    isNotAuthorized: false,
    selectedCategoryTitle: null,
    currentTitlePage: '',
    currentIconPage: null,
    // Токен
    token: CookieService.getCookie('authToken') || null,
    thisErrorToken: null,
    currentRoleUser: Number(localStorage.getItem('_currentRole')) || 1, // его роль
    siteEduAndStud: (document.location.href.includes('edu.donstu.ru') || document.location.href.includes('stud.sssu.ru')),
    showDGTU: (document.location.href.includes('edu.donstu.ru') || process.env.NODE_ENV === 'development'),
    showSTUD: (document.location.href.includes('stud.sssu.ru') || process.env.NODE_ENV === 'development'),
    user: {}, // текущий пользователь
    userInfo: {}, // текущая информация юзер
    initInfo: {}
  },
  getters: {
    authenticated (state) {
      return state.user !== null
    },
    routerHistory (state) {
      return state.routerHistory
    },
    currentTitlePage (state) {
      return state.currentTitlePage
    },
    initInfo (state) {
      return state.initInfo
    },
    currentIconPage (state) {
      return state.currentIconPage
    },
    isAdmin (state) {
      const adminRoles = ['Администратор-НИР', 'Администратор-Сайт']
      return state.user.roles && state.user.roles.filter(f => adminRoles.includes(f.name)).length > 0
    },
    isLibrary (state) {
      return state.user.roles.filter(f => f.name === 'НаучноТехническаяБиблиотека').length > 0
    },
    isCheckOtherTypes (state) {
      return state.user.roles.filter(f => f.name === 'Верификатор иных типов в библиотеке').length > 0
    },
    cuser(state) {
      return state.user
    },
    betaMode (state) {
      return state.betaMode
    },
    currentRole (state) {
      if (state.user.hasOwnProperty('currentRole')) {
        let users = state.user.roles.filter((user) => user.id === state.user.currentRole)
        return users[0].name
      }
    },
    curRoleID (state) {
      return state.currentRoleUser
    },
    currentRoleID (state) {
      if (state.user.hasOwnProperty('currentRole')) {
        let users = state.user.roles.filter((user) => user.id === state.user.currentRole)
        return users[0].id
      }
    },
    currentID (state) {
      return state.user && state.user.hasOwnProperty('userID') ? state.user.userID : null
    },
    userGroupID (state) {
      return state.user && state.user.hasOwnProperty('groupID') ? state.user.groupID : null
    },
    isParent (state) {
      return !!(state.user && state.user.parentID !== null && state.user.parentID !== undefined)
    },
    showStud () {
      return (document.location.href.includes('stud.sssu.ru'))
    },
    showDGTU (state) {
      return state.showDGTU || document.location.href.includes('stud.sssu.ru') || (document.location.href.includes('test.mmis.ru'))
    },
    isLogin (state) {
      return state.user !== null ? state.user.userID !== undefined && state.user.userID !== null: false
    },
    userRoles (state) {
      return state.user.roles ? state.user.roles.filter(f => f.objectTable !== 'ЭлитноеОбразование' || f.objectID === state.educationSpaceID).map(m => m.name) : []
    },
    userFIO (state) {
      return state.user.shortFIO
    },
    userLogin (state) {
      return state.user ? state.user.login : null
    },
    anotherID (state) {
      if (state.user.hasOwnProperty('anotherID')) {
        return state.user.anotherID
      } else {
        return null
      }
    },
    userFullName (state) {
      return state.user !== null ? state.user.fullName : ''
    },
    isEmployee(state) {
      return state.user ? state.user.userID > 0 : false
    }
  },
  mutations: {
    updateRouterHistory (state, data) {
      state.routerHistory.push(data)
      // чистим историю что бы не засорять память
      if (state.routerHistory.length > 10) {
        state.routerHistory.shift()
      }
    },
    updateModules (state, data) {
      state.initInfo.modules = data
    },
    setUser (state, user) {
      state.user = user
    },
    // Токен
    retrieveToken (state, payload) {
      state.token = payload
    },
    thisErrorToken (state, payload) {
      state.thisErrorToken = payload
    },
    createdInfoInitializationApplication (state, payload) {
      state.initInfo = payload
    },
    createdInitializationApplication (state, payload) {
      state.nirWorksModule.initScienceInfo = payload
    },
    setBetaMode (state, value) {
      state.betaMode = (value === true)
    },
    changeYear (state, year) {
      saveYearCache(year)
      state.sYear = year
    },
    selectedCategoryTitle (state, payload) {
      state.selectedCategoryTitle = payload
    },
    currentTitlePage (state, payload) {
      state.currentTitlePage = payload
    },
    currentIconPage (state, payload) {
      state.currentIconPage = payload
    },
    createdInfoApp (state, payload) {
      state.shortName = payload
    },
    // isNotAuthorized
    // Текущая роль препода
    setIsNotAuthorized (state, payload) {
      state.isNotAuthorized = payload
    },
    setCurrentRoleUser (state, payload) {
      state.currentRoleUser = payload
    },
    createdUser (state, payload) {
      state.user = payload
    },
    userInfo (state, payload) {
      state.userInfo = payload
    },
  },
  actions: {
    // Авторизация и Роли
    setCurrentRoleUser (context, payload) {
      context.commit('setCurrentRoleUser', payload)
    },
    selectedCategoryTitle (context, payload) {
      context.commit('selectedCategoryTitle', payload)
    },
    currentTitlePage (context, payload) {
      context.commit('currentTitlePage', payload)
    },
    currentIconPage (context, payload) {
      context.commit('currentIconPage', payload)
    },
    async loadUser (context) {
      await Login.getUserInfo(true).then((response) => {
        if (response === null) {
        } else {
          // this.dispatch('loadEducationSpaces')
          context.commit('createdUser', response)
        }
      }).catch(() => {
      })
    },
    // Авторизация
    retrieveToken (context, payload) {
      return new Promise((resolve, reject) => {
        var returnMsg = { status: null, msg: '' } // переменная для return
        return Vue.prototype.$api.post('/api/tokenauth', // Пост запрос на api
          {
            userName: payload.userName,
            password: payload.password,
            isParent: payload.isParent,
            fingerprint: payload.fingerprint,
            recaptchaToken: payload.recaptchaToken
          })
          .then(response => { // обработка результата
            if (response.state === -1 || response.data.state === -1) { // -1 - ошибка
              returnMsg.msg = response.msg || response.data.msg
              returnMsg.status = -1
              context.commit('thisErrorToken', returnMsg)
              return returnMsg
            }
            if (response.data.data && response.data.data.accessToken) {
              const token = response.data.data.accessToken
              CookieService.setCookie('authToken', token, { path: '/' })
              if (response.data.data.refreshToken) {
                CookieService.setCookie('refreshToken', response.data.data.refreshToken, { path: '/api/Auth/UpdateToken' })
              }
              context.commit('retrieveToken', token)
              Login.getUserInfo(true).then((response) => {
                context.commit('createdUser', response)
                if (router.history.current.name === 'Login') {
                  router.push('/')
                }
              })

              returnMsg.msg = 'Авторизация успешна'
              returnMsg.status = 1
              resolve(response)
              return returnMsg
            } else {
              returnMsg.msg = 'Неизвестная ошибка'
              returnMsg.status = -1
              reject(response)
              return returnMsg
            }
          })
          .catch(error => {
            console.log(error)
            reject(error)
            returnMsg.msg = error.message
            returnMsg.status = -1
            return returnMsg
          })
      })
    },
    destroyToken (context, payload) {
      if (context.getters.isLogin) {
        // context.commit('destroyToken', payload)
      }
    },
    thisErrorToken (context, payload) {
      context.commit('thisErrorToken', payload)
    },
    userInfo (context, payload) {
      context.commit('userInfo', payload)
    },
    async createdInitializationApplication (context) {
      if (navigator.onLine) {
        let response = await Api().get('/api/InitializationApplication')
        context.commit('createdInfoInitializationApplication', response.data.data)
        context.commit('createdInitializationApplication', response.data.data.initScienceInfo)
        context.commit('createdInfoApp', response.data.data.shortName)
        // context.dispatch('initMenu')
        localStorage.setItem('initAppInfo', JSON.stringify(response))
      } else {
        let responseLS = JSON.parse(localStorage.getItem('initAppInfo'))
        if (responseLS && responseLS.data && responseLS.data.data) {
          await context.commit('createdInfoInitializationApplication', responseLS.data.data)
          context.commit('createdInitializationApplication', responseLS.data.data.initScienceInfo)
          context.commit('createdInfoApp', responseLS.data.data.shortName)
        }
        let response = await Api().get('/api/InitializationApplication')
        context.commit('createdInfoInitializationApplication', response.data.data)
        context.commit('createdInitializationApplication', response.data.data.initScienceInfo)
        context.commit('createdInfoApp', response.data.data.shortName)
        // context.dispatch('initMenu')
        localStorage.setItem('initAppInfo', JSON.stringify(response))
      }
    },
  }
})
