function replaceWrongLayout (search) {
  let engCodes = ['`', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', '[', ']', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', ';', '\'', 'z', 'x', 'c', 'v', 'b', 'n', 'm', ',', '.', '{', '}', ':', '"', '<', '>', '~', ' ']
  let rusCodes = ['ё', 'й', 'ц', 'у', 'к', 'е', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ', 'ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю', 'х', 'ъ', 'ж', 'э', 'б', 'ю', 'ё', ' ']
  let rusSearch = Array.from(search).map(letter => engCodes.indexOf(letter)).map(index => rusCodes[index]).join('')
  let engSearch = Array.from(search).map(letter => rusCodes.indexOf(letter)).map(index => engCodes[index]).join('')

  return [rusSearch.length === search.length ? rusSearch : null, engSearch.length === search.length ? engSearch : null]
}
function searchWithRWL (value, search, item) {
  let values = [...Object.values({ ...item })].filter(i => typeof i !== 'object' && typeof i !== 'boolean' && typeof i !== 'number' && typeof i !== 'date')
  let originalSearch = values.some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()))
  let [rusSearchWord, engSearchWord] = replaceWrongLayout(search.toLowerCase())
  let transliteratedSearch = values.some(v => v && (v.toString().toLowerCase().includes(engSearchWord) || v.toString().toLowerCase().includes(rusSearchWord)))
  return originalSearch || transliteratedSearch
}
export default {
  replaceWrongLayout, searchWithRWL
}
