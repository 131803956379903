<template>
  <v-dialog persistent v-model="showDialog" max-width="500">
    <v-card>
      <v-card-title>
        Подтвердите действие
      </v-card-title>
      <v-card-text class="text-left" :class="isDark ? 'white--text': 'black--text'">{{text}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel()">Нет</v-btn>
        <v-btn text @click="confirm()">Да</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: 'Confirm',
  data () {
    return {
      showDialog: false,
      text: 'Тест',
      resolvePromise: undefined,
      rejectPromise: undefined
    }
  },
  computed: {
    isDark () {
      return this.$vuetify.theme.dark
    },
  },
  methods: {
    show (opts = {}) {
      this.text = opts.text

      this.showDialog = true

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    cancel () {
      this.showDialog = false
      this.resolvePromise(false)
    },
    confirm () {
      this.showDialog = false
      this.resolvePromise(true)
    }
  }
}
</script>

<style scoped>

</style>
