<template>
  <div class="overflow-hidden" v-resize="onResize">
    <v-app-bar
      v-if="false"
      app
      :color="bg"
      dark
      class="transitionBackground"
      elevation="0"
      elevate-on-scroll
    >
      <div style="cursor: pointer;" @click="$router.push('/')" class="d-flex align-center">
        <div class="ml-4">
          ДГТУ
        </div>
        <!-- <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
            transition="scale-transition"
            width="40"
        />

        <v-img
            alt="Vuetify Name"
            class="shrink mt-1 hidden-sm-and-down"
            contain
            min-width="100"
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
            width="100"
        /> -->
      </div>

      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text >
            <span class="mr-2">Справочник</span>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :to="item.href"
              v-for="(item, index) in directoryItems"
              :key="index"
          >
            <v-list-item-title >{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text >
            <span class="mr-2">Меню</span>
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
              :to="item.href"
              v-for="(item, index) in mainMenuItems"
              :key="index"
          >
            <v-list-item-title >{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn to="/Login" text v-if="!authenticated">Вход / Регистрация</v-btn>
      <div v-else>
        <v-btn href="/WebApp" text v-if="!isMobile">{{cuser.fio}}</v-btn>
        <v-btn @click="logout()" icon><v-icon>mdi-logout</v-icon> </v-btn>
      </div>
    </v-app-bar>
    <slot></slot>
  </div>
</template>

<script>
  import menu from "@/helpers/api/menu";
  import login from "@/helpers/api/login";

  export default {
    name: "MainLayout",
    data () {
      return {
        mainMenuItems: [],
        directoryItems: [],
        bg: 'primary',
        isMobile: false
      }
    },
    methods: {
      logout() {
        window.getApp.$emit('APP_LOGOUT')
        // login.logOut()
      },
      changeColor() {
        if (this.isMobile || document.documentElement.scrollTop < 100 || document.documentElement.scrollTop > 600) {
          this.bg = 'primary navbar-custom'
        } else {
          this.bg = 'primary'
        }
      },
      onResize () {
        if (window.innerWidth < 900) {
          this.isMobile = true
        } else {
          this.isMobile = false
        }
      }
    },
    computed: {
      cuser() {
        return this.$store.getters.cuser
      },
      authenticated () {
        return this.$store.getters.authenticated
      }
    },
    async mounted() {
      window.onscroll = () => {
        this.changeColor()
      }
      this.mainMenuItems = await menu.getMenuItems()
      this.directoryItems = await menu.getDirectoryItems()
    }
  }
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap');
.navbar-custom {
  border: 0;
  border-radius: 0;
  /* padding-bottom: 2px;
  padding-top: 2px; */
  height: auto !important;
  transition: background, padding 0.8s ease-in-out 0s !important;
}
.transitionBackground {
  transition: background, padding 0.8s ease-in-out 0s !important;
}
/* body {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
</style>
<style scoped>

</style>
