import Api from '@/helpers/iprapi'
export default {
  address: '',
  offConsole: true,
  async get (link, params) {
    let query = '?'
    for (let key in params) {
      query += key + '=' + params[key] + '&'
    }
    if (query !== '?') {
      query = query.substr(0, query.length - 1)
      link = link + query
    }

    return Api().get(link)
      .then(response => {
        if (response) {
          return response.data
        } else {
          return null
        }
      })
  },


}
